:global(#app) {
  .attachment {
    display: inline-block;
  }

  .user {
    margin-right: -8px;
  }

  .users {
    display: inline-flex;
  }

  .popupWrapper {
    height: 100%;
    width: 100%;
  }

  .addButton {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;

    &:hover {
      .iconAddButton {
        opacity: 1;
      }
    }
  }

  .iconAddButton {
    opacity: 0;
  }
}
