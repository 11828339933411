:global(#app) {
  .markdownWrapper {
    width: 100%;
    max-height: 200px;
  }

  .markdown {
    background-color: transparent;
  }
}
