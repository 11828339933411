:global(#app) {
  .tableSettingsButton {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      .iconTableSettingsButton {
        fill: var(--textColor1);
      }
    }
  }

  .iconTableSettingsButton {
    fill: var(--textColor1);
  }
}
