:global(#app) {
  .wrapper {
    max-height: 100%;
    width: 100%;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    color: var(--textColor0);
  }

  .header {
    position: sticky;
    top: 0;
    height: 60px;
    z-index: 1;
    box-shadow: 0 2px 5px var(--shadowBlack01);
  }

  .headerRow {
    background: var(--backgroundSidebar);
    color: var(--textColorWhite);
    font-weight: bold;
  }

  .headerCell {
    padding: 8px;
    text-align: left;
  }

  .bodyRow {
    background: var(--backgroundSidebarItem);

    &:nth-of-type(odd) {
      background: var(--backgroundSidebarItem2);
    }
  }

  .bodyCell {
    padding: 8px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
