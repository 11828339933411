:global(#app) {
  .dropdownContainer {
    position: relative;
    text-align: left;
  }

  .dropdownSearchInput {
    width: 100%;
    padding: 3px 20px 3px 3px;
    background: var(--backgroundColorTmpDarkerField);
    color: var(--textColor1);
    outline: none;
    border-radius: 3px;
    border: 1px solid var(--backgroundPopupSeparator);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::placeholder {
      color: var(--textColor3);
      opacity: 1;
    }

    &:disabled {
      background: var(--backgroundColorTmpDarkerField);
      color: var(--textColor3);
    }

    &:focus {
      border: 1px solid var(--borderOutline2);
    }
  }

  .dropdownSearchInputError {
    border: 1px solid var(--errorRed) !important;

    &::placeholder {
      color: var(--errorRed);
    }
  }

  .dropdownButton {
    display: inline-block;
    overflow: hidden;
    position: absolute;
    right: 1px;
    top: 1px;
  }

  .dropdownButtonError {
    svg {
      fill: var(--errorRed);
    }

    &:hover {
      svg {
        fill: var(--errorRedHover);
      }
    }
  }

  .dropdownMenu {
    position: absolute;
    max-height: calc(100vh / 3);
    background: var(--backgroundColorTmpDarkerField);
    color: var(--textColor1);
  }

  .dropdownMenuWithChildren {
    border: 1px solid var(--borderOutline);
    border-radius: 3px;
  }

  .dropdownItem {
    padding: 3px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      background: var(--backgroundColorTmpHighlighted);
    }
  }

  .dropdownItemSelected {
    background: var(--backgroundColorTmpHighlighted);
  }

  .dropdownItemDefault {
    background: var(--backgroundColorTmpDarkerField2);
    font-weight: 900;
  }

  .icon {
    margin-right: 4px;
  }

  .fullWidth {
    width: 100%;
  }

  .default {
    @extend .fullWidth;
    margin-bottom: 6px;
  }
}
