:global(#app) {
  .default {
    width: 100%;
    padding: 8px;
  }

  .compact {
    width: 100%;
    padding: 2px 4px 2px 6px;
  }
}
