:global(#app) {
  .content {
    min-width: 300px;
  }

  .dropdownMenu {
    z-index: 2001;
  }

  .text {
    color: var(--textColor1);
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 4px;
  }
}
