:global(#app) {
  .attachment {
    display: inline-flex;
    padding: 14px 8px 2px 8px !important; //TODO temp fix for timer placement
    max-width: 100%;
  }

  .popupWrapper {
    height: 100%;
    width: 100%;
  }

  .addButton {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;

    &:hover {
      .iconAddButton {
        opacity: 1;
      }
    }
  }

  .iconAddButton {
    opacity: 0;
  }
}
