:global(#app) {
  .popupWrapper {
    height: 100%;
    width: 100%;
  }

  .editCardButton {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      .iconEditCardButton {
        fill: var(--textColor1);
      }
    }
  }

  .iconEditCardButton {
    fill: var(--textColor1);
  }
}
