:global(#app) {
  .wrapper {
    flex: 1;
    height: 100%;
    min-width: 100%;
    padding: 10px 20px 10px 20px;
    position: relative;
  }

  // .popupWrapper {
  //   display: inline-block;
  //   position: relative;
  //   width: 10px;
  //   height: 20px;
  // }

  // .cover {
  //   border-radius: 2px;
  //   padding: 0 2px;
  //   vertical-align: middle;
  //   width: 100%;
  // }

  // .attachment {
  //   display: inline-block;
  //   line-height: 0;
  //   margin: 0 0 6px 0;
  //   max-width: 100%;
  //   vertical-align: top;
  // }

  .table {
    height: 100%;
    width: 100%;
    display: block;

    // width: 100%;
    // overflow-x: auto;
    table-layout: fixed;
    border-collapse: collapse;
  }

  .tableHeader {
    display: block;
  }

  .tableHeaderDefault {
    height: 60px;
  }

  .tableHeaderCompact {
    height: 40px;
  }

  .tableHeaderRow {
    display: block;

    width: 100%;
    height: 100%;

    display: flex;
  }

  .tableHeaderCell {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    color: var(--textColor1);

    flex-shrink: 0;

    &:last-child {
      flex-shrink: 1;

      .resizer {
        right: 2px;
      }
    }
  }

  .tableHeaderCellInnerWrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    display: inline-flex;
    align-items: center;
  }

  .tableBody {
    display: block;
    // width: 100%;
    overflow-y: auto;
    background: var(--backgroundSidebarItem);
  }

  .tableBodyDefault {
    height: calc(100% - 60px);
  }

  .tableBodyCompact {
    height: calc(100% - 40px);
  }

  .tableBodyRow {
    display: flex;
  }

  .tableBodyRowSelected {
    border: 1px solid var(--borderOutline2);
    background: var(--backgroundCardOpened) !important;
  }

  .tableBodyCell {
    padding: 0; //TODO change to cell in every component instead of here

    display: inline-block;
    flex-shrink: 0;

    display: inline-flex;
    align-items: center;

    &:hover {
      background: var(--shadowBlack015);
    }

    &:last-child {
      flex-shrink: 1;
    }
  }

  // .tableBodyCellCenter {
  //   justify-content: center;
  // }

  .resizer {
    z-index: 1;
    right: -5px;
    position: absolute;
    height: 40%;
    width: 10px;
    top: 35%;
    cursor: ew-resize;

    &:hover {
      &::before {
        background: var(--tableResizeHover);
      }
    }
  }

  .resizer::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 2px;
    background: var(--tableResize);
  }

  .floatingButtonsWrapper {
    pointer-events: none;

    position: sticky;
    left: 90%;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: end;
    width: fit-content;
  }

  .floatingButton {
    pointer-events: auto;

    background: var(--backgroundList);
    color: var(--textColor2);
    height: 36px;
    padding: 8px;
    text-align: left;
    width: fit-content;

    &:hover {
      color: var(--textColor2);
      background: var(--backgroundListButton);

      .addCardButtonIcon {
        fill: var(--textColor2);
      }
    }
  }

  .floatingButtonIcon {
    fill: var(--textColor2);
  }

  .floatingButtonText {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    margin-left: 2px;
    vertical-align: top;
  }

  .iconTableHeader {
    fill: var(--textColor1);
    flex-shrink: 0;
  }

  .sortingIndicator {
    display: inline-flex;
    align-items: center;
  }

  .sortingIconRotated {
    transform: rotate(180deg);
  }

  .sortingIndex {
    margin-left: -3px;
  }
}
